<template>
  <div />
</template>
<script>
  export default {
    data() {
      return {};
    },
    mounted() {
      const { p, q } = this.$route.query;
      if (p) {
        if (q) {
          this.$router.replace({ path: p, query: JSON.parse(atob(q)) });
        } else {
          this.$router.replace({ path: p });
        }
      }
    },
  };
</script>
