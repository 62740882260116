import Vue from 'vue';
import VueRouter from 'vue-router';
import { APP_MOBILE_MODE, APP_MODE, PC_APP } from '@/config';
import Redirect from '@/views/Redirect.vue';
import Login from '@/views/Login.vue';
import APILogin from '@/views/APILogin.vue';

Vue.use(VueRouter);

export const redirectRoute = {
  path: '/Redirect',
  name: 'Redirect',
  component: Redirect,
};

export const loginRoute = {
  path: '/Login',
  name: 'Login',
  component: Login,
};

export const apiLoginRoute = {
  path: '/APILogin',
  name: 'APILogin',
  component: APILogin,
};

export const mainRoute = {
  path: '/',
  name: 'main',
  redirect: loginRoute.path,
};

export const gameRoute = {
  path: '/Games',
  name: 'Games',
  component: () => {
    switch (APP_MODE) {
      case APP_MOBILE_MODE:
        return import(/* webpackChunkName: "chunk-games" */ '@/views/mobile/mGames.vue');
      default:
        return import(/* webpackChunkName: "chunk-games" */ '@/views/pc/Games.vue');
    }
  },
};

export const passwordRoute = {
  path: '/ModifyPWD',
  name: 'ModifyPWD',
  component: () => import(/* webpackChunkName: "chunk-games" */ '@/views/ModifyPWD.vue'),
};

export const limitsRoute = {
  path: '/Limits',
  name: 'Limits',
  component: () => import(/* webpackChunkName: "chunk-games" */ '@/views/Limits.vue'),
};

export const oddsRoute = {
  path: '/OddsHistory',
  name: 'odds',
  component: () => import(/* webpackChunkName: "chunk-games" */ '@/views/OddsHistory.vue'),
};

export const promotionRoute = {
  path: '/promotion',
  name: 'promotion',
  component: () => import(/* webpackChunkName: "chunk-promotion" */ '@/views/Promotion.vue'),
};

export const livesRoute = {
  path: '/lives',
  name: 'lives',
  component: () => import('@/views/Lives.vue'),
};

const routes = [
  mainRoute,
  redirectRoute,
  loginRoute,
  apiLoginRoute,
  passwordRoute,
  gameRoute,
  limitsRoute,
  oddsRoute,
  promotionRoute,
  livesRoute,
];

if (PC_APP) {
  const gameResultRoute = {
    path: '/GameResult',
    name: 'GameResult',
    component: () => import(/* webpackChunkName: "chunk-games" */ '@/views/pc/GameResult.vue'),
  };
  const historyRecordRoute = {
    path: '/HistoryRecord',
    name: 'HistoryRecord',
    component: () => import(/* webpackChunkName: "chunk-games" */ '@/views/pc/HistoryRecord.vue'),
  };
  const historyRecord97Route = {
    path: '/HistoryRecord97/:key/:type',
    name: 'HistoryRecord97',
    component: () => import(/* webpackChunkName: "chunk-games" */ '@/views/pc/HistoryRecord97.vue'),
  };
  const announcementRoute = {
    path: '/Ann',
    name: 'Ann',
    component: () => import(/* webpackChunkName: "chunk-games" */ '@/views/pc/Ann.vue'),
  };
  routes.push(gameResultRoute, historyRecordRoute, historyRecord97Route, announcementRoute);
} else {
  const historyRecord97Route = {
    path: '/HistoryRecord97/:key/:type',
    name: 'HistoryRecord97',
    component: () =>
      import(/* webpackChunkName: "chunk-games" */ '@/views/mobile/mHistoryRecord97.vue'),
  };
  routes.push(historyRecord97Route);
}

routes.push({
  path: '/*',
  redirect: '/',
});

export const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0,
      behavior: 'smooth',
    };
  },
});

export default router;
