import request from './config/request';

// 取得網站
export function apiGetSite() {
  return request({
    url: '/datafresh/WebSitInfoPost',
    method: 'post',
  });
}

// 取得時間
export function apiGetTime() {
  return request({
    url: '/datafresh/systimePost',
    method: 'post',
  });
}

// 登入
export function apiPostLogin({ account, password }) {
  const data = { mbID: account, pw: password };
  return request({
    url: '/mb/sin/login',
    method: 'post',
    data,
  });
}

// token 登入
export function apiPostTokenLogin(token) {
  const data = { MemID: token };
  return request({
    url: '/mb/sin/outApiLogin',
    method: 'post',
    data,
  });
}

// 登出
export function apiPostLogout() {
  return request({
    url: '/mb/sin/logout',
    method: 'post',
    auth: true,
  });
}

// 取得用戶
export function apiGetUser() {
  return request({
    url: '/mb/info/about',
    method: 'post',
    auth: true,
  });
}

// 取得餘額
export function apiGetBalance() {
  return request({
    url: '/mb/info/cash',
    method: 'post',
    auth: true,
  });
}

// 更改密碼
export function apiPutPassword({ newPassword, oldPassword }) {
  const data = { pw: newPassword, oldpw: oldPassword };
  return request({
    url: '/mb/sin/repw',
    method: 'post',
    auth: true,
    data,
  });
}

// 更改名稱
export function apiPutName(name) {
  const data = { Nickname: name };
  return request({
    url: '/mb/info/setNickname',
    method: 'post',
    auth: true,
    data,
  });
}
