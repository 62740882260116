const TOKEN = 'Token';
export const ACCOUNT = 'MBID';
export const REDIRECTED = 'redirected';

export const sessionGetToken = () => sessionStorage.getItem(TOKEN) || '';
export const sessionSetToken = (token) => sessionStorage.setItem(TOKEN, token);
export const sessionClearToken = () => sessionStorage.removeItem(TOKEN);

export const sessionGetAccount = () => sessionStorage.getItem(ACCOUNT) || '';
export const sessionSetAccount = (account) => sessionStorage.setItem(ACCOUNT, account);
export const sessionClearAccount = () => sessionStorage.removeItem(ACCOUNT);

export const sessionIsRedirected = () => !!sessionStorage.getItem(REDIRECTED);
export const sessionMarkRedirected = () => sessionStorage.setItem(REDIRECTED, true);
