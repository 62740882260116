<template>
  <div id="app" :data-i18n="i18nLocale" :data-theme="theme" v-loading.fullscreen.lock="appLoading">
    <router-view v-if="initOk" />
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { redirectRoute, loginRoute, apiLoginRoute, gameRoute, oddsRoute } from '@/router';
  import { sessionIsRedirected, sessionMarkRedirected } from '@/config/session';
  import { LOGIN, LOGOUT } from '@/config/storage';
  import { isMobileDevice } from '@/config/mobile';
  import { gotoPcHost, isMobileHost, gotoMobileHost } from '@/config/host';

  export default {
    data() {
      return {
        initOk: false,
      };
    },
    computed: {
      ...mapState(['appLoading', 'i18nLocale', 'darkTheme']),
      theme() {
        return this.darkTheme ? 'dark' : 'light';
      },
    },
    methods: {
      ...mapActions(['initTheme']),
    },
    beforeMount() {
      const { $store, $route, $router } = this;
      const { getters, commit, dispatch } = $store;
      const routeName = $route.name;

      if (sessionIsRedirected()) {
        if (location.hash.includes(oddsRoute.path)) {
          this.initOk = true;
          return;
        }
      }

      if (routeName === redirectRoute.name) {
        sessionMarkRedirected();
        const { t, a, l } = this.$route.query;
        if (t && a) {
          commit('setSessionToken', t);
          commit('setSessionAccount', a);
          if (l) {
            commit('setI18nLocale', l);
          }
        } else {
          commit('clearSession');
        }
      } else if (routeName === apiLoginRoute.name) {
        sessionMarkRedirected();
        commit('clearSession');
      } else if (!sessionIsRedirected()) {
        sessionMarkRedirected();
        if (isMobileDevice()) {
          if (!isMobileHost()) {
            gotoMobileHost();
            return;
          }
        } else {
          if (isMobileHost()) {
            gotoPcHost();
            return;
          }
        }
      }

      window.addEventListener(
        'storage',
        (event) => {
          if (event.key === LOGIN) {
            const { sessionToken } = this.$store.state;
            // 使用者多開時, 以 storage 事件登出不同的使用者
            if (sessionToken && event.newValue && sessionToken !== event.newValue) {
              $store.commit('showLoading');
              $store
                .dispatch('logout')
                .catch((error) => {
                  console.error(error);
                })
                .finally(() => {
                  $store.commit('clearSession');
                  if ($router.currentRoute.name !== loginRoute.name) {
                    $router.replace(loginRoute).finally(() => {
                      $store.commit('hideLoading');
                    });
                  } else {
                    $store.commit('hideLoading');
                  }
                });
            }
          }
          // 關閉使用者開啟的相關視窗
          else if (event.key === LOGOUT) {
            const { sessionToken } = this.$store.state;
            // 使用者多開時, 以 storage 事件登出不同的使用者
            if (sessionToken) {
              $store.commit('showLoading');
              $store.commit('clearSession');
              if ($router.currentRoute.name !== loginRoute.name) {
                $router.replace(loginRoute).finally(() => {
                  $store.commit('hideLoading');
                });
              }
            }
            window.close();
          }
        },
        false
      );

      dispatch('initSite')
        .then(() => {
          if (getters.sessionOk) {
            return dispatch('updateUser')
              .then(() => {
                if (routeName === loginRoute.name || routeName === apiLoginRoute.name) {
                  return $router.replace(gameRoute);
                } else if (routeName === redirectRoute.name) {
                  const { p, q } = this.$route.query;
                  if (p) {
                    if (q) {
                      this.$router.replace({ path: p, query: JSON.parse(atob(q)) });
                    } else {
                      this.$router.replace({ path: p });
                    }
                  } else {
                    return $router.replace(gameRoute);
                  }
                }
              })
              .catch(() => {
                commit('clearSession');
                if ($route.name !== loginRoute.name) {
                  return $router.replace(loginRoute);
                }
              });
          } else {
            if (routeName === apiLoginRoute.name) {
            } else if (routeName !== loginRoute.name) {
              return $router.replace(loginRoute);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.initOk = true;
        });
    },
    mounted() {
      this.initTheme();
    },
  };
</script>
