import { apiGetChatTexts, apiGetChatEmojis } from '@/api/game';

export default {
  namespaced: true,
  state: {
    host: null,
    socket: null,
    roomID: null,
    ChatList: [],
    isChatInputFocus: false,
    defaultMSG: [],
    emojiDataRaw: [],
    loginResultCode: -100,
  },
  getters: {
    emojiGroups(state) {
      const groups = state.emojiDataRaw.reduce((acc, it) => {
        const group = it.DirName || 'common';
        !acc[group] && (acc[group] = []);
        acc[group].push({
          base64Img: it.ImgName,
          symbol: it.Symbol,
          uniqueKey: '#' + group + it.Symbol,
        });
        return acc;
      }, {});
      return groups;
    },
  },
  mutations: {
    setHost(state, host) {
      state.host = host;
    },
    setSocket(state, socket) {
      state.socket = socket;
    },
    setRoomID(state, roomID) {
      const { roomID: oldRoomID } = state;
      if (oldRoomID !== roomID) {
        state.roomID = roomID;
      }
    },

    SetChatList(state, val) {
      state.ChatList.length = 0;
      state.ChatList = val;
    },
    setChatInputFocus(state, val) {
      state.isChatInputFocus = val;
    },
    setDefaultMSG(state, val) {
      state.defaultMSG = val;
    },
    setEmojiData(state, val) {
      state.emojiDataRaw = val;
    },
    PushChatList(state, val) {
      state.ChatList.push(val);
    },
    ClearChatList(state, val) {
      state.ChatList.length = 0;
      state.ChatList = [];
    },
    SetLoginResultCode(state, val) {
      state.loginResultCode = val;
    },
  },
  actions: {
    init({ commit }, host) {
      commit('setHost', host);
      if (host) {
        const url = `https://${host}`;
        apiGetChatTexts(url).then((response) => {
          const { data } = response;
          if (data) {
            commit('setDefaultMSG', data);
          }
        });
        apiGetChatEmojis(url).then((response) => {
          const { data } = response;
          if (data) {
            commit('setEmojiData', data);
          }
        });
      }
    },
  },
};
