import router from '@/router';
import { apiGetGameCat, apiGetLiveEvents2 } from '@/api/game';
import { getMenuIconByCatID } from '@/utils/SportLib';
import { MOBILE_APP } from '@/config';

export default {
  namespaced: true,
  state: {
    cats: {},
    lives: [],
    activeCats: [],
    activeCat: 0,
    activeLive: null,
  },
  getters: {
    activeLives(state) {
      const { lives, activeCat } = state;
      if (activeCat === 0) {
        return lives;
      } else if (activeCat === 1) {
        return lives.filter(({ CatID }) => CatID === 1 || CatID === 31);
      } else if (activeCat === 'olympic') {
        return lives.filter(({ Olympic }) => Olympic);
      } else {
        return lives.filter(({ CatID }) => CatID === activeCat);
      }
    },
  },
  mutations: {
    setActiveCat(state, cat) {
      state.activeCat = cat;
    },
    showLive(state, live) {
      state.activeLive = live;
    },
    hideLive(state) {
      state.activeLive = null;
    },
  },
  actions: {
    updateCats({ state }) {
      return apiGetGameCat().then((response) => {
        response.forEach((cat) => {
          const { CatID } = cat;
          cat.icon = getMenuIconByCatID(CatID);
        });
        state.cats = response.reduce((map, cat) => {
          cat.GroupCatIDs.forEach((id) => {
            map = {
              ...map,
              ...{
                [id]: cat,
              },
            };
          });
          return map;
        }, {});
        state.cats.olympic = {
          Name: '奧運',
        };
      });
    },
    updateLives({ state }) {
      return apiGetLiveEvents2().then((response) => {
        const { cats, lives, activeCats } = state;
        const {
          data: { List: leagues },
        } = response;
        lives.length = 0;
        leagues.forEach((league) => {
          const { CatID, LeagueID, LeagueNameStr: LeagueName, Team: events } = league;
          const cat = cats[CatID];
          events.forEach((event) => {
            const live = {
              ...event,
              CatID: cat.CatID,
              CatName: cat.Name,
              LeagueID,
              LeagueName,
            };
            lives.push(live);
          });
        });
        lives.sort((live1, live2) => {
          const { CatID: cat1, ScheduleTimeStr: time1 } = live1;
          const { CatID: cat2, ScheduleTimeStr: time2 } = live2;
          if (cat1 !== cat2) return cat1 - cat2;
          return time1.localeCompare(time2);
        });
        activeCats.length = 0;
        lives.forEach(({ CatID }) => {
          if (!activeCats.includes(CatID)) {
            activeCats.push(CatID);
          }
        });
        if (lives.some(({ Olympic }) => Olympic)) {
          activeCats.push('olympic');
        }
      });
    },
    openHome() {
      if (MOBILE_APP) {
        router.replace('/Games');
      } else {
        const { href: url } = router.resolve({
          path: 'Games',
        });
        if (url) {
          window.open(url, 'games');
        }
      }
    },
    openTable({ commit, dispatch }, live) {
      if (live) {
        const type = live.IsRun ? 2 : 3;
        const cat = live.CatID;
        if (MOBILE_APP) {
          window.OddData.clear();
          commit('setLoading', true);
          commit(
            'Game/setCatIDAndGameTypeAndWagerType',
            {
              selectGameType: type,
              selectCatID: cat,
              selectWagerTypeKey: 1,
            },
            { root: true }
          );
          commit('MoreGame/closeMoreGameList', null, { root: true });
          dispatch('Game/updateGameDetail', { clear: true }, { root: true }).finally(() => {
            commit('setLoading', false);
          });
          router.replace('/Games');
        } else {
          const { href: url } = router.resolve({
            path: 'Games',
            query: { t: type, c: cat },
          });
          if (url) {
            window.open(url, 'games');
          }
        }
      }
    },
  },
};
