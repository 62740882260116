import store from '@/store';

export const openNewWindow = (path = '', name = '_blank', q = null) => {
  const { sessionToken, sessionAccount, i18nLocale } = store.state;
  let query = '';
  if (sessionToken && sessionAccount) {
    query = `t=${sessionToken}&a=${sessionAccount}`;
  }
  if (i18nLocale) {
    if (query) {
      query += '&';
    }
    query += `l=${i18nLocale}`;
  }
  if (path) {
    if (query) {
      query += '&';
    }
    query += `p=${path}`;
  }
  if (q) {
    if (query) {
      query += '&';
    }
    query += `q=${btoa(JSON.stringify(q))}`;
  }
  const url = `${location.protocol}//${location.host}/#/Redirect`;
  const newUrl = query ? `${url}?${query}` : url;
  // console.log(newUrl);
  window.open(newUrl, name);
};
