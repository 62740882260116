import store from '@/store';

export function getPcHost() {
  const segments = location.hostname.split('.');
  const first = segments[0].trim().toLowerCase();
  if (first === 'm') {
    const newSegments = segments.slice(1);
    const hostname = newSegments.join('.');
    return `${location.protocol}//${hostname}`;
  } else if (first === 'm1') {
    const newSegments = segments.slice(1);
    const hostname = newSegments.join('.');
    return `${location.protocol}//w1.${hostname}`;
  } else {
    return location.origin;
  }
}

export function gotoPcHost() {
  const { sessionToken, sessionAccount } = store.state;
  const query = sessionToken && sessionAccount ? `?t=${sessionToken}&a=${sessionAccount}` : '';
  location.href = `${getPcHost()}/#/Redirect${query}`;
}

export function isMobileHost() {
  const segments = location.hostname.split('.');
  const first = segments[0].trim().toLowerCase();
  return first === 'm' || first === 'm1';
}

export function getMobileHost() {
  const segments = location.hostname.split('.');
  const first = segments[0].trim().toLowerCase();
  if (first === 'm' || first === 'm1') {
    return location.origin;
  } else {
    const newSegments = first === 'www' ? segments.slice(1) : segments;
    if (first === 'w1') {
      const newSegments2 = newSegments.slice(1);
      newSegments2.unshift('m1');
      const hostname = newSegments2.join('.');
      return `${location.protocol}//${hostname}`;
    } else {
      newSegments.unshift('m');
      const hostname = newSegments.join('.');
      return `${location.protocol}//${hostname}`;
    }
  }
}

export function gotoMobileHost() {
  const { sessionToken, sessionAccount } = store.state;
  const query = sessionToken && sessionAccount ? `?t=${sessionToken}&a=${sessionAccount}` : '';
  location.href = `${getMobileHost()}/#/Redirect${query}`;
}

export function getOldHost() {
  const segments = location.hostname.split('.');
  const first = segments[0].trim().toLowerCase();
  const newSegments = ['m', 'www'].includes(first) ? segments.slice(1) : segments;
  newSegments.unshift('old');
  const hostname = newSegments.join('.');
  return `${location.protocol}//${hostname}`;
}

export function gotoOldHost() {
  location.href = getOldHost();
}
