import pako from 'pako';
import rootStore from '@/store';
import gameStore from '@/store/modules/Game';
import moreStore from '@/store/modules/MoreGame';
import settingStore from '@/store/modules/Setting';
import { GAME_FAVORITE_CAT, MOBILE_APP } from '@/config';

const textDecoder = new TextDecoder('utf-8');

const encode = (data) => {
  const text = JSON.stringify(data);
  const zip = pako.gzip(text, { to: 'string' });
  return btoa(zip);
};

const decode = (data) => {
  const bytes = atob(data);
  const unzip = pako.ungzip(Uint8Array.from(bytes, (char) => char.charCodeAt(0)));
  const text = textDecoder.decode(unzip);
  return JSON.parse(text);
};

let socket = null;
let helloInterval = null;
let times = 0;
let locking = false;

const getUrl = () => {
  let host = '';
  if (process.env.NODE_ENV !== 'development') {
    host = location.host;
  } else {
    const url = process.env.VUE_APP_DEV_DOMAIN;
    host = url.replace(/(^\w+:|^)\/\//, '');
  }
  let newHost = host;
  const segments = host.split('.');
  const segment0 = segments[0].trim().toLowerCase();
  if (['m', 'm1', 'w1', 'www'].includes(segment0)) {
    const newSegments = segments.slice(1);
    newHost = newSegments.join('.');
  }
  const url = `wss://${newHost}/wsGameDataSource`;
  // console.log(`socket-URL: ${url}`);
  return url;
};

const sendSetLogin = () => {
  const mobile = MOBILE_APP ? 1 : 0;
  const { sessionToken, sessionAccount } = rootStore.state;
  const data = `${sessionToken},${sessionAccount},${mobile}`;
  socket.send(`@SetLogin[${data}`);
  // console.log('socket-SetLogin', data);
};

const sendSetHello = () => {
  socket.send('@SetHello');
  // console.log('socket-SetHello');
};

const sendSetGameSmall = () => {
  const data = [];
  const {
    selectGameType: GameType,
    selectCatID: CatID,
    selectWagerTypeKey: WagerTypeKey,
  } = gameStore.state;
  const { isShowMoreGame, teamData } = moreStore.state;
  if (CatID === GAME_FAVORITE_CAT) {
    const { favorites } = settingStore.state.UserSetting;
    const EvtIDs = favorites.join(',');
    data.push({ FavoritesModel: true, EvtIDs });
  } else if (WagerTypeKey) {
    data.push({ GameType, CatID, WagerTypeKey });
  } else {
    data.push({ GameType, CatID });
  }
  if (isShowMoreGame && teamData) {
    const { EvtID } = teamData;
    data.push({ EvtID });
  }
  socket.send(`@SetGameSmaill[${JSON.stringify(data)}`);
  // console.log('socket-SetGameSmall', data);
};

const onInit = (data) => {
  const result = JSON.parse(data);
  // console.log('socket-Init:', result);
  const { code } = result;
  if (code === 200) {
    sendSetLogin();
  } else {
    console.warn('socket-Init 失敗');
  }
};

const onSetLogin = (data) => {
  const result = decode(data);
  // console.log('socket-SetLogin', result);
  const { code } = result;
  if (code === 200) {
    clearSetHello();
    helloInterval = setInterval(() => {
      sendSetHello();
    }, 50000); // 50s
    sendSetGameSmall();
  } else {
    console.warn('socket-SetLogin 失敗');
  }
};

const onSetGameSmall = (data) => {
  const result = decode(data);
  // console.log('socket-SetGameSmall', result);
  const { code } = result;
  if (code === 200) {
    // console.log('socket-SetGameSmall 成功', result);
  } else {
    console.warn('socket-SetGameSmall 失敗');
  }
};

const onGetGameSmall = (data) => {
  const result = decode(data);
  // console.log('socket-GetGameSmall', result);
  const { Main, More } = result;
  if (Main) {
    const { code, data } = Main;
    if (code === 200) {
      const { List, GameScoreHead, MoreCoutToEvtID } = data;
      if (List?.length) {
        rootStore.commit('Game/updateGameList', {
          updateData: List,
          isUpdateFromOtherStore: false,
        });
      }
      if (GameScoreHead?.length) {
        rootStore.commit('Game/updateTeamData', {
          updateData: GameScoreHead,
        });
      }
      if (MoreCoutToEvtID?.length) {
        rootStore.commit('Game/updateMoreCount', {
          updateData: MoreCoutToEvtID,
        });
      }
    }
  }
  const { isShowMoreGame } = moreStore.state;
  if (isShowMoreGame && More) {
    const { code, data } = More;
    const { GameScore, List } = data;
    if (code === 200) {
      if (List?.length) {
        rootStore.commit('MoreGame/updateMoreGameData', {
          updateData: List,
          isUpdateFromOtherStore: true,
        });
      }
      /*
      if (GameScore) {
        if (GameScore.length) {
          moreStore.state.moreGameData.GameScore = GameScore[0];
        } else {
          moreStore.state.moreGameData.GameScore = GameScore;
        }
      } */
    }
  }
};

const onMessage = (event) => {
  // console.log(event);
  const result = event.data.split('[');
  const [type, data] = result;
  switch (type) {
    case '@init':
      onInit(data);
      break;
    case '@SetLogin':
      onSetLogin(data);
      break;
    case '@SetGameSmaill':
      onSetGameSmall(data);
      break;
    case '@GetGameSmaill':
      onGetGameSmall(data);
      break;
  }
};

const onOpen = (event) => {
  // console.log(event);
  // times = 0;
  locking = false;
};

const onClose = (event) => {
  // console.log(event);
  reconnectSocket();
};

const onError = (event) => {
  // console.error(event);
  reconnectSocket();
};

const clearSetHello = () => {
  if (helloInterval) {
    clearInterval(helloInterval);
    helloInterval = null;
    // console.log('clear hello');
  }
};

const initSocket = () => {
  const url = getUrl();
  socket = new WebSocket(url);
  socket.onmessage = onMessage;
  socket.onopen = onOpen;
  socket.onclose = onClose;
  socket.onerror = onError;
  // console.log('socket', socket);
  lockSocket();
};

const reconnectSocket = () => {
  if (times > 5 || locking) {
    return;
  }
  times += 1;
  initSocket();
};

const lockSocket = () => {
  locking = true;
  setTimeout(() => {
    locking = false;
  }, 2000);
};

export const triggerSocket = () => {
  if (socket) {
    if (socket.readyState === WebSocket.OPEN) {
      sendSetGameSmall();
    } else if (socket.readyState === WebSocket.CLOSED) {
      reconnectSocket();
    }
  } else {
    initSocket();
  }
};

export const isSocketOk = () => {
  return socket && socket.readyState !== WebSocket.CLOSED;
};

window.clearSocketHello = clearSetHello;
window.setSocketTimes = (newTimes = 6) => {
  times = newTimes;
  console.log('socket times: ', times);
};
